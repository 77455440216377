
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { updateLatestDetail, saveBaseInfo } from "@/API/localRegistration";
import Title from "../components/title.vue";
import moment from "moment";
import { message } from "ant-design-vue";
export default defineComponent({
  components: {
    Title: Title,
  },
  setup() {
    const columns = [
      {
        title: "No.",
        customRender: (text: any) => {
          return text.index + 1;
        },
      },
      {
        title: "Dealer/SWT code",
        dataIndex: "dealerCode",
        key: "dealerCode",
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        key: "dealerName",
      },
      {
        title: "Province Ⅱ",
        dataIndex: "province",
        key: "province",
      },
      {
        title: "Covered part Ⅱ area",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "Applicable Type Class",
        dataIndex: "applicableTypeClass",
        key: "applicableTypeClass",
      },
      {
        title: "Operation",
        dataIndex: "operation",
        key: "operation",
        slots: { customRender: "operation" },
      },
    ];

    const router = useRouter();
    const route = useRoute();
    const dealerName = ref<string>(route.query.dealerName as string);
    const city = ref<string>(route.query.city as string);
    const dealerCode = ref<string>(route.query.dealerCode as string);
    const dealerId = route.query.dealerId;
    const tableData = ref<any[]>(JSON.parse(route.query.tableData as string));
    const typeClassInfo = ref<string[]>(
      JSON.parse(route.query.typeClassInfo as string)
    );
    console.log("typeClassInfo", typeClassInfo);
    const checkList = ref<string[]>(
      JSON.parse(route.query.checkList as string)
    );
    const saveParams = ref<any[]>(JSON.parse(route.query.saveParams as string));
    const effectiveTime = ref<any>();
    tableData.value.forEach((item) => {
      item.isView = false;
      item.isDelete = false;
    });

    const handleView = (record: any) => {
      record.isView = true;
    };
    const handleDelete = (record: any) => {
      record.isDelete = true;
    };
    const handleDeleteAll = () => {
      tableData.value.forEach((item) => {
        item.isDelete = true;
      });
    };

    const handleSave = () => {
      const statusList = tableData.value.filter((item) => !item.isDelete);
      if (statusList.length > 0) {
        return message.error("You haven‘t finished adding");
      }
      const eTime =
        moment(effectiveTime.value).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(effectiveTime.value).format("YYYY-MM-DD");

      const params = typeClassInfo.value.map((item: any) => {
        return {
          id: item.id,
          dealerId,
          applicableTypeClass: item.applicableTypeClass,
          region: item.region,
          subRegion: item.subRegion,
          effectiveTime: eTime,
          status: 0,
        };
      });
      const data = {
        applicableTypeClass: checkList.value,
        effectiveTime: eTime,
        status: 0,
        dealerApplicableList: params,
      };
      Promise.all([
        saveBaseInfo(saveParams.value),
        updateLatestDetail(data as any),
      ]).then(([res1, res2]) => {
        message.success("You have deleted dealer successfully");
      });
    };

    const handleCancel = () => {
      router.push("/localRegistration/focusAreaSet/dealerAdjustment/delete");
    };
    return {
      columns,
      dealerName,
      city,
      dealerCode,
      tableData,
      effectiveTime,
      handleView,
      handleDelete,
      handleDeleteAll,
      handleSave,
      handleCancel,
    };
  },
});
